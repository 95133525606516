import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Modal from '../../component/Modal';
import StrapiAPI from 'api/StrapiAPI';

export default () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);

    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const cleanedData = { ...data, captchaResponse }
        console.log(cleanedData)
        const { success } = await StrapiAPI.sendContactUs(cleanedData)
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <div>
            <div>
                <h1 className="text-center mb-8">Contact Us</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>First Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                        {errors.firstName && <div className="error">First Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Last Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Email Address</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                        {errors.emailAddress && <div className="error">Email is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Phone Number</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="cellPhone" placeholder="Phone Number" />
                        {errors.cellPhone && <div className="error">Phone Number is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full inline-block">
                        <label>How can we help you?</label>
                        <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="questions" placeholder="How can we help you?" />
                    </div>
                    <div className="mb-8 px-4 w-full inline-block flex justify-center items-center">
                        <input type="checkbox" id="isSubscribed" name="isSubscribed" ref={register()} defaultChecked />
                        <label htmlFor="isSubscribed" className="ml-2">Subscribe to our promotional emails</label>
                    </div>
                    <div className="mb-8 mx-4 inline-block flex flex-col items-center">
                        <button type="submit" className="bg-gray-900 text-white w-full px-8 py-4">Submit</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}>
                <div className="p-8 bg-white max-w-xl mx-auto">
                    {success ?
                        <p>Thank you for contacting us! We'll be in touch soon!</p> :
                        <p>Something went wrong, please try submitting again or calling our number!</p>
                    }
                </div>
            </Modal>
        </div>
    );
}

export const SimpleContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);

    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const cleanedData = { ...data, captchaResponse }
        console.log(cleanedData)
        const { success } = await StrapiAPI.sendContactUs(cleanedData)
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <div>
            <div>
                <h1 className="text-center mb-8">Contact Us</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>First Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                        {errors.firstName && <div className="error">First Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Last Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Email Address</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                        {errors.emailAddress && <div className="error">Email is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Phone Number</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="cellPhone" placeholder="Phone Number" />
                        {errors.cellPhone && <div className="error">Phone Number is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full inline-block">
                        <label>How can we help you?</label>
                        <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="questions" placeholder="How can we help you?" />
                    </div>
                    <div className="mb-8 px-4 w-full inline-block flex justify-center items-center">
                        <input type="checkbox" id="isSubscribed" name="isSubscribed" ref={register()} defaultChecked />
                        <label htmlFor="isSubscribed" className="ml-2">Subscribe to our promotional emails</label>
                    </div>
                    <div className="mb-8 mx-4 inline-block flex flex-col items-center">
                        <button type="submit" className="bg-red-500 text-white w-full px-8 py-4 mb-8">Submit</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}>
                <div className="p-8 bg-white max-w-xl mx-auto">
                    {success ?
                        <p>Thank you for contacting us! We'll be in touch soon!</p> :
                        <p>Something went wrong, please try submitting again or calling our number!</p>
                    }
                </div>
            </Modal>
        </div>
    );
}



export const GiftCertContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);

    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const cleanedData = { ...data, captchaResponse }
        console.log(cleanedData)
        const { success } = await StrapiAPI.sendContactUs(cleanedData, 'Gift Certificate')
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <div>
            <div>
                <h1 className="text-center mb-8">Gift Certificate Request</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>First Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                        {errors.firstName && <div className="error">First Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Last Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Email Address</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                        {errors.emailAddress && <div className="error">Email is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Gift Card Amount</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="number" name="amount" placeholder="Gift Card Amount" />
                        {errors.amount && <div className="error">Gift Card Amount</div>}
                    </div>
                    {/* <div className="mb-8 px-4 w-full inline-block">
                        <label>How can we help you?</label>
                        <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="questions" placeholder="How can we help you?" />
                    </div> */}
                    <div className="mb-8 px-4 w-full inline-block flex justify-center items-center">
                        <input type="checkbox" id="isSubscribed" name="isSubscribed" ref={register()} defaultChecked />
                        <label htmlFor="isSubscribed" className="ml-2">Subscribe to our promotional emails</label>
                    </div>
                    <div className="mb-8 mx-4 inline-block flex flex-col items-center">
                        <button type="submit" className="bg-gray-900 text-white w-full px-8 py-4">Submit</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}>
                <div className="p-8 bg-white max-w-xl mx-auto">
                    {success ?
                        <p>Thank you for contacting us! We'll be in touch soon!</p> :
                        <p>Something went wrong, please try submitting again or calling our number!</p>
                    }
                </div>
            </Modal>
        </div>
    );
}

