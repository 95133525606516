import React, { useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import FrameL from 'component/FrameL';
import ImageCarousel from 'component/ImageCarousel';


import './style.css';

import SuiteBox1 from 'assets/suites-box1.jpg';
import SuiteBox2 from 'assets/suites-box2.jpg';
import { IoIosClose } from 'react-icons/io';
import { toKebabCase } from 'utils/helpers';
import { GiftCertContactForm } from 'component/ContactForm';

export default ({ section }) => {
  const { heading, subsection = [], photo, __component } = section;
  const isComplex = __component === 'section.complex-page-section';
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);
  const selectedSubSect = subsection[selectedSub];
  const {hash} = useLocation();
  useEffect(()=>{
    if(hash.indexOf('&') != -1){
        const mainhash = hash.split('&')[0];
        const subhash= hash.split('&')[1];
      
      var idx = subsection.findIndex((val,i)=>{
          if(val.heading.startsWith(subhash.toUpperCase())){
            return true;
          }
      });
      if(idx >=0){
        setSelectedSub(idx);
        setIsExpanded(true);
      }

    }
  },[hash])

  return <section className='md:px-6' id={toKebabCase(heading)}>
    <FrameL ratio='16:9' ratioAboveSm='21:9' Image={photo}>
      <div className=''>
        <div className='w-1/4 md:w-1/5 absolute z-10 bottom-0 left-0 md:-left-8 md:-top-8'>
          <FrameL src={isComplex ? SuiteBox1 : SuiteBox2} isExternal={false} ratio="1:1">
            <div>
              <h3 className='text-center text-lg sm:text-2xl'>{heading}</h3>
            </div>
          </FrameL>
        </div>
        <div className='w-full md:w-1/3 absolute h-full right-0 bg-black bg-opacity-50 stack-l uppercase text-white text-center' style={{
          '--justify': 'center'
        }}>
          {subsection.map(({ heading }, i) => {
            return <button className={`uppercase ${selectedSub === i ? 'text-primary-green' : ''}`} key={i} onClick={() => {
              setSelectedSub(i);
              setIsExpanded(true);
            }}>{heading}</button>
          })}
        </div>
      </div>
    </FrameL>

    <div className={`bg-emerald-50 section__expanded ${isExpanded ? 'active' : ''}`}>
      {selectedSubSect &&
        (isComplex ? <ComplexSubsection subsection={selectedSubSect}></ComplexSubsection> :
          <SimpleSubsection subsection={selectedSubSect}></SimpleSubsection>)}
      <button className='float-right' onClick={() => {
        setIsExpanded(false);
      }}><IoIosClose className='icon'></IoIosClose></button>
    </div>

  </section >
}

const SimpleSubsection = ({ subsection }) => {
  const { heading, description } = subsection;
  return <div className='stack-l p-4 lg:p-12'>
    <h3>{heading}</h3>
    <ReactMarkdown className='markdown-image'>{description}</ReactMarkdown>
    {heading.toUpperCase() === 'GIFT CERTIFICATE' &&
      <GiftCertContactForm></GiftCertContactForm>
    }
  </div>
}


const ComplexSubsection = ({ subsection }) => {
  const { photos, heading, description, details, bookingLink, phone } = subsection;
  return <div className='stack-l p-4 lg:p-12' style={{
    '--space': 0
  }}>
    {photos.length === 1 ? <FrameL Image={photos[0]} ratio='16:9' /> :
      <ImageCarousel key={heading} ratio='16:9' carouselProgressClasses='mb-4 lg:mb-12'>
        {photos.map(p => {
          return <FrameL Image={p}></FrameL>
        })}
      </ImageCarousel>}

    <div className="sidebar-l items-start relative" style={{
      '--sideWidth': '300px',
      '--sbSpace': '0',
      '--contentMin': '60%'
    }}>
      <ReactMarkdown className='p-4 bg-white markdown-bulleted-list'>{details}</ReactMarkdown>
      <div className='stack-l p-4 bg-orange-100 lg:-mt-8 lg:-mr-8' style={{
        '--space': 'var(--s2)'
      }}>
        <h3>{heading}</h3>
        <ReactMarkdown className='markdown-bulleted-list'>{description}</ReactMarkdown>
        <div className="cluster-l items-center">
          <a className='btn btn-primary uppercase' href={bookingLink}>Book Now</a>
          <span>{phone}</span>
        </div>
      </div>
    </div>

  </div>
}