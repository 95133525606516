import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { AiFillInstagram, AiFillFacebook } from 'react-icons/ai';
import { FaTripadvisor } from 'react-icons/fa';


import './style.css';
import { CmsDataWrapper } from 'context/cmsData';
import { Img } from 'component/FrameL';
import StripeBanner from 'assets/stripe-banner.png';

const Footer = ({ cmsData }) => {
    const { 'core-data': coreData = {} } = cmsData;
    const { awards, hotelName, address, email, phoneNumber, bookingEngineUrl, instagram, facebook, tripAdvisor } = coreData;
    return <footer className='stack-l pb-8 bg-black text-white text-center' style={{
        '--space': 'var(--s3)'
    }}>
        <div className="h-5 bg-repeat-x bg-contain bg-left-top" style={{ backgroundImage: `url(${StripeBanner})` }}></div>
        <div className="cluster-l" style={{
            '--justify': 'center'
        }}>
            {awards.map(({ url, photo }) => {
                return <a target='_blank' className='w-[11rem]' href={url} key={url}>
                    <Img Image={photo}></Img>
                </a>
            })}
        </div>

        <div className="cluster-l uppercase footer-links" style={{
            '--justify': 'center'
        }}>
            <Link to="/about#the-inn&directions">
                Directions
            </Link>
            <Link to="/about#the-inn&faq">
                FAQ
            </Link>
            <Link to="/about#the-inn&awards">
                Awards
            </Link>
            <Link to="/rooms#inn-policies">
                Policies
            </Link>
        </div>

        <div>
            <p>{phoneNumber}</p>
            <ReactMarkdown>{address}</ReactMarkdown>
            <p>&copy; {new Date().getFullYear()} {hotelName}</p>
        </div>

        <div className="cluster-l" style={{
            '--cSpace': 'var(--s-4)',
            '--justify': 'center'
        }}>
            <a href={instagram} aria-label='Instagram Link'>
                <AiFillInstagram className='icon'></AiFillInstagram>
            </a>

            <a href={facebook} aria-label='Facebook Link'>
                <AiFillFacebook className='icon'></AiFillFacebook>
            </a>
            <a href={tripAdvisor} aria-label='Facebook Link'>
                <FaTripadvisor className='icon'></FaTripadvisor>
            </a>
        </div>
    </footer>;
};

export default CmsDataWrapper(Footer, ['core-data']);
