import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import PageTemplate from './component/PageTemplate';
import { CmsDataWrapper } from 'context/cmsData';

const HomePage = React.lazy(() => import('./container/Home'));

const ContactPage = React.lazy(() => import('./container/Contact'));
const PolicyPage = React.lazy(() => import('./container/Policy'));
const GalleryPage = React.lazy(() => import('./container/Gallery'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <Switch>
          <Route path="/rooms" component={CmsDataWrapper(PageTemplate('room'), ['room'])} />
          <Route path="/specials" component={CmsDataWrapper(PageTemplate('special'), ['special'])} />
          <Route path="/explore" component={CmsDataWrapper(PageTemplate('explore'), ['explore'])} />
          <Route path="/dining" component={CmsDataWrapper(PageTemplate('dining'), ['dining'])} />
          <Route path="/about" component={CmsDataWrapper(PageTemplate('about'), ['about'])} />
          <Route path="/contact" component={() => <ContactPage />} />
          <Route path="/policies" component={() => <PolicyPage />} />
          <Route path="/gallery" component={() => <GalleryPage />} />
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        <Footer />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
