import React, { useState, useEffect } from 'react';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle, IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import './style.css';
import FrameL from '../FrameL';

let timeout;

const ImageCarousel = ({ children, ratio = "16:9", doesResizeHeight = false, carouselProgressClasses = 'mb-4', fixedHeight, ratioAboveSm, ratioAboveMd, ratioAboveLg, ratioAboveXl, autoPlaySpeed, className }) => {
    const [index, setIndex] = useState(0);

    const handleForward = () => {
        setIndex(i => (i + 1) % children.length);
    }

    const handlePrevious = () => {
        setIndex(i => (i - 1 + children.length) % children.length);
    }

    useEffect(() => {
        timeout && clearTimeout(timeout);
        if (autoPlaySpeed) {
            timeout = setTimeout(() => {
                handleForward();
            }, autoPlaySpeed);
        }
    }, [index]);

    return (<FrameL className={className}
        doesResizeHeight={doesResizeHeight}
        fixedHeight={fixedHeight}
        hasImage={false}
        ratio={ratio}
        ratioAboveSm={ratioAboveSm}
        ratioAboveMd={ratioAboveMd}
        ratioAboveLg={ratioAboveLg}
        ratioAboveXl={ratioAboveXl}>
        <div>
            {children.map((child, i) => {
                return <div key={i} className={`absolute inset-0 carousel-item ${index === i ? 'active' : ''}`}>
                    {child}
                </div>
            })}

            <div className={`absolute bottom-0 opacity-80 w-full  text-white text-3xl carousel__progress-container ${carouselProgressClasses}`}>
                {children.map((_, i) => {
                    if (index !== i) {
                        return <button key={i} onClick={() => setIndex(i)} className='carousel__progress-bar'></button>
                    }
                    return <button key={i} onClick={() => setIndex(i)} className='carousel__progress-bar active'>
                        <div></div>
                    </button>
                })}
            </div>
            <button className="absolute cursor-pointer carousel__prev m-4" onClick={handlePrevious}>
                <IoIosArrowDropleftCircle
                    className="text-white opacity-80 text-4xl"
                ></IoIosArrowDropleftCircle>
            </button>
            <button className="absolute cursor-pointer carousel__next m-4" onClick={handleForward}>
                <IoIosArrowDroprightCircle
                    className="text-white opacity-80 text-4xl"
                ></IoIosArrowDroprightCircle>
            </button>
        </div>
    </FrameL>
    );
};

ImageCarousel.defaultProps = {
    autoPlaySpeed: 6000
};

export default ImageCarousel;
