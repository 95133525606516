import FrameL from 'component/FrameL';
import ImageCarousel from 'component/ImageCarousel';
import PageSection from 'component/PageSection';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import ColorSplash from 'assets/color-splash.jpg';
import { toKebabCase } from 'utils/helpers';

export default (type = 'room') => ({ cmsData }) => {
  const { heroImages, introduction, sections } = cmsData[type];

  return <main className='xl:container mx-auto px-4 stack-l pb-16' style={{
    '--space': 'var(--s5)'
  }}>
    <section className='break-out-banner'>
      {heroImages.length === 1 ? <FrameL Image={heroImages[0]} fixedHeight="70vh" doesResizeHeight={true} /> :
        <ImageCarousel fixedHeight="70vh" doesResizeHeight={true}>
          {heroImages.map((image, i) => {
            return <FrameL key={i} Image={image} fixedHeight="70vh" doesResizeHeight={true}></FrameL>
          })}
        </ImageCarousel>}
    </section>

    <section className='max-w-screen-lg mx-auto'>
      <div className="sidebar-l-right">
        <div>
          <ReactMarkdown className='fancy-first text-lg leading-loose'>{introduction}</ReactMarkdown>
        </div>

        <div className='stack-l uppercase text-center'>
          <div className="h-2 w-1/2 mx-auto" style={{ backgroundImage: `url(${ColorSplash})` }}></div>
          {sections.map(({ heading }, i) => {
            return <a key={i} href={`#${toKebabCase(heading)}`}>{heading}</a>
          })}
        </div>
      </div>
    </section>

    {sections.map((s, i) => {
      return <PageSection section={s} key={i}></PageSection>
    })}
  </main>
}
