import { fetchGet, fetchPost } from './helper';


export default class {
    static sendContactUs(data, bookingType = 'General') {
        let { firstName, lastName, emailAddress, cellPhone = '', city = '', groupName = '', dateDesired = '', numberOfRooms = 0, numberOfGuests = 0, questions = '', marketingSource, isSubscribed = false, captchaResponse, amount } = data;

        if (amount) {
            questions = `I would like to purchase a gift certificate of $${amount}`
        }

        const body = {
            firstName,
            lastName,
            emailAddress,
            cellPhone,
            city,
            groupName,
            dateDesired,
            numberOfRooms,
            numberOfGuests,
            questions,
            marketingSource,
            bookingType,
            isSubscribed,
            captchaResponse
        }
        return fetchPost(`${process.env.REACT_APP_API_DOMAIN}/contacts`, body);
    }
}
