import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose, IoMdCall, IoIosPin } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';

// import moment from 'moment';
// import 'react-dates/initialize';
// import { DateRangePicker } from 'react-dates';
// import 'react-dates/lib/css/_datepicker.css';

import { Img } from 'component/FrameL';
import './style.css';
import StripeBanner from 'assets/stripe-banner.png';

function DesktopNavbar({ CoreData }) {
  const { bookingEngineUrl, hotelLogo, hotelName } = CoreData;
  // const [bookingStart, setBookingStart] = useState(moment(new Date()));
  // const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
  // const [bookingFocusedInput, setBookingFocusedInput] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const { pathname } = useLocation();
  const isHome = pathname === '/';

  useEffect(() => {
    const handleScrollClose = () => {
      if (window.scrollY < 50) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<>
    {!isOpen && <div className={`fixed top-0 left-0 p-4 z-50 w-full flex justify-between items-center`}>
      <button onMouseMove={() => {
        setIsOpen(true);
      }}
        aria-label="Menu"
        className="p-2 tailwind-block bg-black">
        <IoMdMenu className="text-4xl text-white"></IoMdMenu>
      </button>

      <a
        href={bookingEngineUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`bg-white text-black mr-2 btn uppercase`}>
        Book Now
      </a>
    </div>}
    <div className={`navbar__bg_container text-white ${isOpen ? 'visible' : ''}`}>
      {isHome && <Link to="/" className='stack-l no-underline items-center pt-8 pb-4'>
        <Img className='navbar__logo__width' Image={hotelLogo}></Img>
        <h1>{hotelName}</h1>
      </Link>}
      <div className={`navbar__link cluster-l`} style={{
        '--cSpace': '2rem',
        '--justify': 'center'
      }}>
        {!isHome && <Link to="/" className='mr-auto text-xl'>
          <h4>{hotelName}</h4>
        </Link>}

        <Link to="/rooms">
          Rooms
        </Link>
        <Link to="/specials">
          Specials
        </Link>
        <Link to="/gallery">
          Gallery
        </Link>
        <Link to="/dining">
          Dining
        </Link>
        <Link to="/explore">
          Explore
        </Link>
        <Link to="/about">
          About
        </Link>
        <Link to="/contact">
          Contact
        </Link>

        {!isHome && <div className='ml-auto' />}
      </div>
      <div className="h-5 bg-repeat-x bg-contain bg-left-top" style={{ backgroundImage: `url(${StripeBanner})` }}></div>
    </div>
    <div className={`fixed mt-1 mr-4 top-0 right-0 flex flex-row z-10`}>
      {/* <DateRangePicker
        startDate={bookingStart}
        startDateId="start"
        endDate={bookingEnd}
        endDateId="end"
        onDatesChange={({ startDate, endDate }) => {
          setBookingStart(startDate);
          setBookingEnd(endDate);
        }}
        small={true}
        focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => setBookingFocusedInput(focusedInput)}
      >
      </DateRangePicker> */}
    </div>
  </>
  );
}

function MobileNavbar({ CoreData }) {
  const { hotelLogo, hotelName } = CoreData;
  const [isOpen, setIsOpen] = useState(false);


  return (<div className="fixed z-20">
    <div className={`navbar__link fixed p-4 top-0 left-0 right-0 text-white flex justify-between items-center`}>
      <button className="p-2 bg-black" onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-4xl text-white"></IoMdMenu>
      </button>
      <Link className="bg-black bg-opacity-50 pointer-events-auto" to="/" aria-label="Home">
        <Img Image={hotelLogo} className='navbar__logo__width mx-auto' />
        <h3 className='text-center no-underline'>{hotelName}</h3>
      </Link>
      <div className="w-8"></div>
    </div>

    <div className={`navbar__link text-xl bg-black text-white stack-l text-center p-8 py-16 mobile_nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 text-4xl m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <Link to="/" className='' onClick={() => {
        setIsOpen(false);
      }}>
        <Img className="navbar__logo__width mx-auto" Image={hotelLogo} />
        <h3 className='text-center mb-8'>{hotelName}</h3>
      </Link>

      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
      </Link>
      <Link to="/specials" onClick={() => {
        setIsOpen(false);
      }}>
        Specials
      </Link>
      <Link to="/gallery" onClick={() => {
        setIsOpen(false);
      }}>
        Gallery
      </Link>
      <Link to="/dining" onClick={() => {
        setIsOpen(false);
      }}>
        Dining
      </Link>
      <Link to="/explore" onClick={() => {
        setIsOpen(false);
      }}>
        Explore
      </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        About
      </Link>
      <Link to="/contact" onClick={() => {
        setIsOpen(false);
      }}>
        Contact
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  return (
    <React.Fragment>
      <div className="z-50 relative">
        <DesktopNavbar CoreData={CoreData} />
      </div>
      {/* <div className="block lg:hidden">
        <MobileNavbar CoreData={CoreData} />
      </div> */}
    </React.Fragment>
  );
}

export default NavBar;